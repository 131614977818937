<template>
  <div>
    <el-popover
      v-model="popoverVisible"
      placement="bottom"
      trigger="click"
      popper-class="el-tiptap-popper"
    >
      <div class="el-tiptap-popper__menu">
        <div class="el-tiptap-popper__menu__item">
          <create-table-popover
            @createTable="createTable"
          />
        </div>
        <div class="el-tiptap-popper__menu__item__separator" />
        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.addColumnBefore"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.add_column_before') }}</span>
        </div>

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.addColumnAfter"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.add_column_after') }}</span>
        </div>

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.deleteColumn"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.delete_column') }}</span>
        </div>

        <div class="el-tiptap-popper__menu__item__separator" />

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.addRowBefore"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.add_row_before') }}</span>
        </div>

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.addRowAfter"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.add_row_after') }}</span>
        </div>

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.deleteRow"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.delete_row') }}</span>
        </div>

        <div class="el-tiptap-popper__menu__item__separator" />

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !enableMergeCells }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.mergeCells"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.merge_cells') }}</span>
        </div>

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !enableSplitCell }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.splitCell"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.split_cell') }}</span>
        </div>
        <div class="el-tiptap-popper__menu__item__separator" />

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="openColorChange"
        >
          <span>Изменить фон ячейки</span>
        </div>

        <div class="el-tiptap-popper__menu__item__separator" />

        <div
          :class="{ 'el-tiptap-popper__menu__item--disabled': !isTableActive }"
          class="el-tiptap-popper__menu__item"
          @mousedown="hidePopover"
          @click="editorContext.commands.deleteTable"
        >
          <span>{{ editorContext.t('editor.extensions.Table.buttons.delete_table') }}</span>
        </div>
      </div>
      <el-dialog
        title="Изменение фона ячейки"
        :visible.sync="styleDialog"
        :append-to-body="true"
      >
        <div style="display:flex;justify-content: center;">
          <el-color-picker
            v-model="colorSelect"
            size="mini"
            inactive-color="black"
            color-format="hex"
            @change="submitColor($event)"
          />
        </div>
      </el-dialog>
      <el-tooltip slot="reference" content="Таблица" effect="dark" placement="top">
        <command-button
          :is-active="isTableActive"
          :enable-tooltip="true"
          :tooltip="editorContext.t('editor.extensions.Table.tooltip')"
          :readonly="editorContext.isCodeViewMode"
          :custom-element="true"
          svg="M464 32c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48h-416c-26.5 0-48-21.5-48-48v-352c0-26.5 21.5-48 48-48h416zM224 416v-96h-160v96h160zM224 256v-96h-160v96h160zM448 416v-96h-160v96h160zM448 256v-96h-160v96h160z"
        />
      </el-tooltip>
    </el-popover>
  </div>
</template>
<script>

import CommandButton from '../command-button.vue'
import CreateTablePopover from './create-table-popover.vue'
import { isTableActive, enableMergeCells, enableSplitCell } from './index'
export default {
  name: 'CreateTableButton',
  components: { CreateTablePopover, CommandButton },
  props: {
    editorContext: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      popoverVisible: false,
      styleDialog: false,
      colorSelect: ''
    }
  },
  computed: {
    isTableActive () {
      return isTableActive(this.editorContext.editor.state)
    },
    enableMergeCells () {
      return enableMergeCells(this.editorContext.editor.state)
    },
    enableSplitCell () {
      return enableSplitCell(this.editorContext.editor.state)
    }
  },
  methods: {
    submitColor (value) {
      this.editorContext.commands.setCellAttr({ name: 'background', value })
      this.styleDialog = false
    },
    openColorChange () {
      if (this.isTableActive) {
        const { from, to } = this.editorContext.editor.state.selection
        this.editorContext.editor.state.doc.nodesBetween(from, to, (node) => {
          if ((node.type.name === 'table_header' || node.type.name === 'table_cell') && node.attrs.background) {
            this.colorSelect = node.attrs.background
          }
        })
        this.styleDialog = true
      }
    },
    createTable ({ row, col }) {
      this.editorContext.commands.createTable({
        rowsCount: +row,
        colsCount: +col,
        withHeaderRow: true
      })

      this.hidePopover()
    },
    hidePopover () {
      this.popoverVisible = false
    }

  }
}
</script>
<style scoped lang="scss">

.el-tiptap-popper {
  &.el-popper {
    min-width: 0;
  }

  &__menu {
    &__item {
      color: $color;
      cursor: pointer;
      padding: 8px 0;

      &:hover {
        color: $primary
      }

      &--active {
        color: $primary;
      }

      &--disabled {
        cursor: default;
        opacity: .2;

        &:hover {
          color: inherit;
        }
      }

      &__separator {
        border-top: 1px solid $border;
        height: 0;
        margin: 5px 0;
        width: 100%;
      }
    }
  }
}
.button-color {
  width: 40px;
  height: 40px;
  color: $color;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}
.button-color:hover {
  background-color: $stripe;
}
</style>
