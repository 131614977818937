// @ts-ignore
import { BulletList } from 'element-tiptap'
import CommandButton from '../command-button.vue'
export default class CustomListItem extends BulletList {
  menuBtnView ({ isActive, commands, t }: any) {
    return [
      {
        component: CommandButton,
        componentProps: {
          isActive: isActive.bullet_list(),
          customElement: true,
          svg: 'M48 48c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM48 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM48 368c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM496 384c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 224c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320z',
          command: commands.bullet_list,
          tooltip: t('editor.extensions.BulletList.tooltip')
        },
        componentEvents: {
          command () {
            if (!isActive.bullet_list()) {
              if (isActive.ordered_list()) {
                commands.bullet_list()
              } else {
                commands.indent()
                commands.bullet_list()
              }
            } else {
              commands.outdent()
              commands.bullet_list()
            }
          }
        }
      }
    ]
  }
}
