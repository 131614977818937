import { Indent } from 'element-tiptap'
// @ts-ignore
import { TextSelection, AllSelection, Transaction } from 'prosemirror-state'
import CommandButton from '../command-button.vue'

export const enum IndentProps {
  max = 7,
  min = 0,

  more = 1,
  less = -1,
}
export function clamp (val: number, min: number, max: number): number {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

function updateIndentLevel (tr: Transaction, delta: number): Transaction {
  const { doc, selection } = tr

  if (!doc || !selection) { return tr }

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr
  }

  const { from, to } = selection

  doc.nodesBetween(from, to, (node: { type: any }, pos: number) => {
    const nodeType = node.type
    if (
      nodeType.name === 'paragraph' ||
      nodeType.name === 'heading' ||
      nodeType.name === 'blockquote'
    ) {
      tr = setNodeIndentMarkup(tr, pos, delta)
      return false
    }
    return true
  })

  return tr
}

function setNodeIndentMarkup (tr: any, pos: number, delta: number): any {
  if (!tr.doc) { return tr }

  const node = tr.doc.nodeAt(pos)
  if (!node) { return tr }

  const minIndent = IndentProps.min
  const maxIndent = IndentProps.max
  const indent = clamp(
    (node.attrs.indent || 0) + delta,
    minIndent,
    maxIndent
  )

  if (indent === node.attrs.indent) { return tr }

  const nodeAttrs = {
    ...node.attrs,
    indent
  }
  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
}

export function createIndentCommand (delta: number) {
  return (state: { selection?: any; tr?: any }, dispatch: (arg0: any) => any) => {
    const { selection } = state
    let { tr } = state
    tr = tr.setSelection(selection)
    tr = updateIndentLevel(tr, delta)

    if (tr.docChanged) {
      dispatch && dispatch(tr)
      return true
    }

    return false
  }
}
export default class IndentCustom extends Indent {
  get name () {
    return 'indent'
  }

  get defaultOptions () {
    return {
      minIndent: IndentProps.min,
      maxIndent: IndentProps.max
    }
  }

  commands () {
    return {
      indent: () => createIndentCommand(IndentProps.more),
      outdent: () => createIndentCommand(IndentProps.less)
    }
  }

  keys () {
    return {
      Tab: createIndentCommand(IndentProps.more),
      'Shift-Tab': createIndentCommand(IndentProps.less)
    }
  }

  menuBtnView ({ commands, t }: any) {
    return [
      {
        component: CommandButton,
        componentProps: {
          command: commands.indent,
          icon: 'el-icon-s-unfold',
          tooltip: t('editor.extensions.Indent.buttons.indent.tooltip')
        }
      },
      {
        component: CommandButton,
        componentProps: {
          command: commands.outdent,
          icon: 'el-icon-s-fold',
          tooltip: t('editor.extensions.Indent.buttons.outdent.tooltip')
        }
      }
    ]
  }
}
