
import { Vue } from 'vue-property-decorator'

const INIT_GRID_SIZE = 5
const MAX_GRID_SIZE = 10
const DEFAULT_SELECTED_GRID_SIZE = 2

interface GridSize {
  row: number;
  col: number;
}
export default class CreateTablePopover extends Vue {
  popoverVisible = false

  tableGridSize: GridSize = {
    row: INIT_GRID_SIZE,
    col: INIT_GRID_SIZE
  }

  selectedTableGridSize: GridSize = {
    row: DEFAULT_SELECTED_GRID_SIZE,
    col: DEFAULT_SELECTED_GRID_SIZE
  }

  selectTableGridSize (row: number, col: number): void {
    if (row === this.tableGridSize.row) {
      this.tableGridSize.row = Math.min(row + 1, MAX_GRID_SIZE)
    }

    if (col === this.tableGridSize.col) {
      this.tableGridSize.col = Math.min(col + 1, MAX_GRID_SIZE)
    }
    this.selectedTableGridSize.row = row
    this.selectedTableGridSize.col = col
    this.$forceUpdate()
  }

  onMouseDown (row: number, col: number): void {
    this.$nextTick(() => {
      this.popoverVisible = false
      this.resetTableGridSize()
    })
    this.$emit('createTable', { row, col })
  }

  saveTable ({ row, col }: {row: number, col: number}) {
    this.$nextTick(() => {
      this.popoverVisible = false
      this.resetTableGridSize()
    })
    this.$emit('createTable', { row, col })
  }

  resetTableGridSize (): void {
    this.$nextTick(() => {
      this.tableGridSize = {
        row: INIT_GRID_SIZE,
        col: INIT_GRID_SIZE
      }

      this.selectedTableGridSize = {
        row: DEFAULT_SELECTED_GRID_SIZE,
        col: DEFAULT_SELECTED_GRID_SIZE
      }
    })
    this.$forceUpdate()
  }
};
