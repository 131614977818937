import { Link } from 'element-tiptap'
import linkModal from '../link/linkModal.vue'

export default class CustomLink extends Link {
  menuBtnView ({ editor, t }: any) {
    return [{
      component: linkModal,
      componentProps: {
        editor,
        tooltip: t('editor.extensions.Link.add.tooltip')
      }
    }]
  }
}
