import { Image } from 'element-tiptap'
// @ts-ignore
import { Node as ProsemirrorNode } from 'prosemirror-model'
/* eslint-disable no-console */
export function readFileDataUrl (file: File): Promise<any> {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    // @ts-ignore
    reader.onload = readerEvent => resolve(readerEvent.target.result)
    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}
const enum ImageDisplay {
  INLINE = 'inline',
  BREAK_TEXT = 'block',
  FLOAT_LEFT = 'left',
  FLOAT_RIGHT = 'right',
}

// @ts-ignore
function getAttrs (dom: HTMLElement): { [key: string]: any } {
  const { cssFloat, display } = dom.style
  let { width, height } = dom.style

  let dp = dom.getAttribute('data-display') || dom.getAttribute('display')
  if (dp) {
    dp = /(inline|block|left|right)/.test(dp) ? dp : ImageDisplay.INLINE
  } else if (cssFloat === 'left' && !display) {
    dp = ImageDisplay.FLOAT_LEFT
  } else if (cssFloat === 'right' && !display) {
    dp = ImageDisplay.FLOAT_RIGHT
  } else if (!cssFloat && display === 'block') {
    dp = ImageDisplay.BREAK_TEXT
  } else {
    dp = ImageDisplay.INLINE
  }
  // @ts-ignore
  width = width || dom.getAttribute('width') || null
  // @ts-ignore
  height = height || dom.getAttribute('height') || null

  return {
    src: dom.getAttribute('src') || '',
    title: dom.getAttribute('title') || '',
    alt: dom.getAttribute('alt') || '',
    width: width == null ? null : parseInt(width, 10),
    height: height == null ? null : parseInt(height, 10),
    display: dp
  }
}

function toDOM (node: ProsemirrorNode) {
  const { src, alt, title, width, height, display } = node.attrs

  const attrs: { [key: string]: any } = {
    src,
    alt,
    title,
    width,
    height
  }

  attrs['data-display'] = display

  return ['img', attrs]
}
export class Logger {
  static warn (msg: string) {
    console.warn(`${'[Element-Tiptap Tip]'} ${msg}`)
  }

  static error (msg: string) {
    console.error(`${'[Element-Tiptap Tip]'} ${msg}`)
  }
}

export default class CustomImage extends Image {
  get defaultOptions () {
    return {
      defaultWidth: null,
      defaultDisplay: 'inline',
      urlPattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      uploadRequest: null
    }
  }

  get schema () {
    return {
      inline: true,
      attrs: {
        src: {
          default: ''
        },
        alt: {
          default: ''
        },
        title: {
          default: ''
        },
        width: {
          default: this.defaultOptions.defaultWidth
        },
        height: {
          default: null
        },
        display: {
          default: this.defaultOptions.defaultDisplay
        }
      },
      group: 'inline',
      draggable: true,
      parseDOM: [{ tag: 'img[src]', getAttrs }],
      toDOM
    }
  }
}
