

import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tooltip } from 'element-ui'

@Component({
  components: {
    [Tooltip.name]: Tooltip
  }
})
export default class CommandButton extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  readonly icon!: string

  @Prop({
    type: String,
    default: ''
  })
  readonly svg!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isActive!: boolean

  @Prop({
    type: String,
    required: true
  })
  readonly tooltip!: string

  @Prop({
    type: Boolean,
    required: true
  })
  readonly enableTooltip!: boolean

  @Prop({
    type: Function
  })
  readonly command!: Function

  @Prop({
    type: Boolean,
    default: false
  })
  readonly customElement!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly readonly!: boolean

  private get commandButtonClass (): object {
    return {
      'el-tiptap-editor__command-button': true,
      'el-tiptap-editor__command-button--active': this.isActive,
      'el-tiptap-editor__command-button--readonly': this.readonly
    }
  }

  onClick () {
    if (!this.readonly) { if (this.customElement) { this.$emit('command') } else { this.command() } }
  }
}
