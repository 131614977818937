
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Blockquote,
  Italic,
  Strike,
  ListItem,
  LineHeight,
  TableCell,
  TableHeader,
  TableRow,
  FormatClear,
  History,
  Link,
  TextAlign,
  TextColor,
  FontSize,
  Underline,
  Fullscreen,
  HardBreak,
  HorizontalRule,
  FontType
} from 'element-tiptap'
import { Component, Prop, Model, Watch, mixins } from 'nuxt-property-decorator'
import CustomTable from '~/utils/element-tiptap-extensions/table/index'
import CustomIdent from '~/utils/element-tiptap-extensions/ident/index'
import CustomImage from '~/utils/element-tiptap-extensions/image/index'
import CustomListItem from '~/utils/element-tiptap-extensions/bullet-list/index'
import OrderedList from '~/utils/element-tiptap-extensions/ordered-list/index'
import CustomLink from '~/utils/element-tiptap-extensions/link/index'
import Mixins from '~/store/mixins'

@Component({
  mixins: [Mixins.FilesMixin]
})
export default class UiTiptap extends mixins(Mixins.FilesMixin) {
  /**
   * *________________ Model ______________________
   */
  @Model('change')

  /**
   * *________________ Props ______________________
   */
  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    value!: string

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    target!: string

  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
    vLoading!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: () => true
  })
    charCounterCount!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: () => true
  })
    showMenubar!: boolean

  @Prop({
    type: String,
    required: false,
    default: () => 'json'
  })
    output!: string

  @Prop({
    type: String,
    required: false,
    default: () => 'ru'
  })
    lang!: string

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    width!: string

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    height!: string

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    urlPreview!: string

  /**
   * *________________ Data ______________________
   */
  extensions = [
    new Doc(),
    new Text(),
    new Paragraph(),
    // @ts-ignore
    new Heading({ level: 5 }),
    // @ts-ignore
    new FontSize({
      fontSizes: ['8', '10', '12', '14', '16', '18', '20', '22', '24', '30', '36', '48', '60', '72']
    }),
    new Bold(),
    new Blockquote(),
    new Italic(),
    new Strike(),
    new Underline(),
    new TextColor(),
    // @ts-ignore
    new CustomIdent(),
    new TextAlign(),
    new ListItem(),
    new CustomListItem(),
    new OrderedList(),
    new LineHeight(),
    // @ts-ignore
    new CustomTable({ resizable: true }),
    new TableRow(),
    new TableCell(),
    new TableHeader(),
    new History(),
    new FormatClear(),
    new HorizontalRule(),
    // @ts-ignore
    new FontType({
      fontTypes: {
        Arial: 'Arial',
        'Arial Black': 'Arial Black',
        Georgia: 'Georgia',
        Impact: 'Impact',
        'Inter Tight': 'Inter Tight',
        Tahoma: 'Tahoma',
        'Times New Roman': 'Times New Roman',
        Verdana: 'Verdana',
        'Courier New': 'Courier New',
        'Lucida Console': 'Lucida Console',
        Monaco: 'Monaco',
        monospace: 'monospace'
      }
    }),
    // @ts-ignore
    new CustomImage({
      uploadRequest: async (file: File) => {
        try {
          const [data] = await this.uploadFile({
            files: [file],
            target: this.target,
            type: 'image'
          }, { notifyMessage: 'Изображение загружено' })
          return this.$imageUrl(data.id, { preview: this.urlPreview })
        } catch (e) {}
      }
    }),
    new Link(),
    new HardBreak(),
    new CustomLink(),
    new Fullscreen()
  ]

  text = ''

  /**
   * *________________ Watch ______________________
   */
  @Watch('value')
  onChangeValue () {
    if (!this.value) {
      this.text = ''
    } else if (this.checkEmptyContent(this.value)) {
      this.value = ''
    } else if (this.output === 'html') {
      this.text = this.value
    } else {
      this.text = JSON.parse(this.value)
    }
  }

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    if (!this.value) {
      this.text = ''
    } else if (this.checkEmptyContent(this.text)) {
      this.value = ''
    } else if (this.output === 'html') {
      this.text = this.value
    } else {
      this.text = JSON.parse(this.value)
    }
  }

  /**
   * *________________ Methods ______________________
   */
  updateText (textValue: any) {
    if (this.checkEmptyContent(textValue)) {
      textValue = ''
    }
    this.text = textValue
    this.$emit('change', typeof this.text === 'string' || this.output === 'html' ? this.text : JSON.stringify(this.text))
  }

  checkEmptyContent (value: string) {
    return value === '{"type":"doc","content":[{"type":"paragraph","attrs":{"textAlign":null,"indent":null,"lineHeight":null}}]}' || value === '{}' || value === '<p></p>'
  }
}

