// @ts-ignore
import { OrderedList } from 'element-tiptap'
import CommandButton from '../command-button.vue'
export default class CustomListItem extends OrderedList {
  menuBtnView ({ isActive, commands, t }: any) {
    return [
      {
        component: CommandButton,
        componentProps: {
          isActive: isActive.ordered_list(),
          command: commands.ordered_list,
          customElement: true,
          svg: 'M61.8 401c19.6 5.1 28.7 20.5 28.7 34.9 0 21.4-14.3 44.1-48.5 44.1-16.6 0-29.3-4.8-37-9.4-5.8-4.2-6.3-9.8-2.6-15.9l5.6-9.3c3.9-6.6 9.1-7 15.6-3.1 4.1 1.7 11 3.1 15.5 3.1 10.2 0 14.4-3.5 14.4-8.2 0-6.6-5.6-9.1-15.9-9.1h-4.7c-6 0-9.3-2.1-12.3-7.9l-1-1.9c-2.5-4.8-1.2-9.8 2.8-14.9l5.6-7c2.9-3.6 7.8-9.1 11-12.3h-22.8c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h57c7.5 0 11.3 4 11.3 11.3v3.3c0 0.2 0 0.6 0 0.9 0 4.2-2.3 10.2-5.1 13.3zM496 224c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM496 384c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-320c-8.8 0-16-7.2-16-16v-32c0-8.8 7.2-16 16-16h320zM16 160c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h16v-64h-8c-4.4 0-8-3.6-8-8 0-1 0.4-2.6 0.8-3.6l8-16c1.2-2.4 4.4-4.4 7.1-4.4h24c4.4 0 8 3.6 8 8v88h16c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-64zM12.1 320c-7 0-12.1-4-12.1-11.4v-4c0-47.3 51-56.4 51-69.1 0-7.2-6-8.8-9.3-8.8h-0.1c-3 0-7.2 1.7-9.4 3.8-5.1 4.9-10.5 7-16.1 2.4l-8.6-6.9c-5.8-4.5-7.2-9.8-2.8-15.4 6.7-8.8 19.1-18.8 40.5-18.8 19.5 0 44.5 10.5 44.5 39.6 0 37.8-45 46.2-48.3 56.4h38.7c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-67.9z',
          tooltip: t('editor.extensions.OrderedList.tooltip')
        },
        componentEvents: {
          command () {
            if (!isActive.ordered_list()) {
              if (isActive.bullet_list()) {
                commands.ordered_list()
              } else {
                commands.indent()
                commands.ordered_list()
              }
            } else {
              commands.outdent()
              commands.ordered_list()
            }
          }
        }
      }
    ]
  }
}
